import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import styled from '@emotion/styled';

const Article = styled.article`
    max-width: 1060px;
    margin: 0 auto;
    padding: 32px 1em;

    > h2 {
        color: #333333;
    }

    > h3 {
        margin-top: 1.5em;
        font-size: 24px;
    }

    > p {
        margin: 1em 0;
    }

    @media (min-width: 1280px) {
        padding: 64px 1em;
    }
`;

const Policy: Page = ({ data }) => {
    const heroImage = data.allFile.edges[0].node.childImageSharp.gatsbyImageData;

    return (
        <main>
            <DefaultLayout title="Privacy Policy" heroImage={heroImage}>
                <Seo
                    title="Privacy Policy | Bloom La Vie Health"
                    description="This notice describes how medical information about you may be used and disclosed and how you can get access to this information. Please review it carefully."
                />
                <Article>
                    <h2>Notice of Privacy Practices</h2>
                    <p>
                        This notice describes how medical information about you may be used and
                        disclosed and how you can get access to this information. Please review it
                        carefully.
                    </p>
                    <h3>Uses and Disclosures</h3>
                    <p>
                        The following categories describe the different ways in which we may use and
                        disclose your individually identifiable health information unless you
                        object:
                    </p>
                    <h3>Treatment </h3>
                    <p>
                        Your health information may be used by professionals to evaluate your
                        health, diagnosing medical conditions, and providing treatment. For example,
                        results of laboratory tests and procedures will be available in your medical
                        record to all health professionals who may provide treatment or who may be
                        consulted by staff members.
                    </p>
                    <h3>Payment</h3>
                    <p>
                        Your health information may be used to bill and collect payment for the
                        services and items you may receive from us. We may use your health
                        information to bill you directly for services and items.
                    </p>
                    <h3>Health Care Operations </h3>
                    <p>
                        Your health information may be used as necessary to support the day-to-day
                        activities and management of Bloom La Vie Health. For example, information
                        on the services you received may be used to support budgeting and financial
                        reporting, activities to evaluate and promote quality, to develop protocols
                        and clinical guidelines, to develop training programs, and to aid in
                        credentialing medical review, legal services, and insurance.
                    </p>
                    <h3>Appointment Reminders</h3>
                    <p>
                        Your health information will be used by our staff to contact you and send
                        you appointment reminders.
                    </p>
                    <h3>Information About Treatments</h3>
                    <p>
                        Your health information may be used to send you information that you may
                        find interesting on the treatment and management of your medical condition.
                        We may also send you information describing other health-related products
                        and services that we believe may interest you.
                    </p>
                    <h3>Law Enforcement</h3>
                    <p>
                        Your health information may be disclosed to law enforcement agencies to
                        support government audits and inspections, to facilitate law enforcement
                        investigations, and to comply with government-mandated reporting.
                    </p>
                    <h3>Release of Information to Family/Friends </h3>
                    <p>
                        Our practice may release your health information to a friend or family
                        member that is involved in your care, or who assists in taking care of you.
                        For example, a parent or guardian may ask that a babysitter take their child
                        for an appointment. In this example, the babysitter or friend may have
                        access to this child’s medical information.
                    </p>
                    <h3>Patient Mass Communication</h3>
                    <p>
                        We may use your name and email address(es) and/or text numbers to contact
                        you with bulk messaging. For instance, to share new promotions for the
                        clinic, to send clinic newsletters, or to notify you of a physician’s
                        upcoming absence, such as for vacations.
                    </p>
                    <p>Other uses and disclosures in certain special circumstances</p>
                    <ul>
                        <li>
                            Public Health Risks - (i.e. vital statistics, child abuse/neglect,
                            exposure to communicable diseases, reporting reactions to drugs or
                            problems with products or devices.)
                        </li>
                        <li>Health Oversight Activities </li>
                        <li>
                            Lawsuits and Similar Proceedings – May use or disclose in response to a
                            court or administrative order if you are involved in a lawsuit or
                            similar proceeding or response to a discovery request, subpoena, or
                            another lawful process.
                        </li>
                        <li>
                            Deceased Patients – May be required to release to a medical examiner or
                            coroner. If necessary, we may also release information for the funeral
                            director to perform their jobs.
                        </li>
                        <li>Organ and Tissue Donation </li>
                        <li>Serious Threats to Health or Safety </li>
                        <li>
                            Military - If you are a member of the U.S. or foreign military forces
                            (including veterans) and if required by the appropriate authorities.
                        </li>
                        <li>National Security </li>
                        <li>
                            Inmates – Our practice may disclose your health information to
                            correctional institutions or law enforcement officials if you are an
                            inmate or under the custody of a law enforcement official. Disclosure
                            would be necessary for the institution to provide health care services
                            to you, for the safety and security of the institution, and/or to
                            protect your health and safety or the health and safety of others.
                        </li>
                        <li>Worker’s Compensation </li>
                    </ul>
                    <p>
                        Disclosures of your health information or its use for any purpose other than
                        those listed above requires your specific written authorization. If you
                        change your mind after authorizing a use or disclosure of your information,
                        you may submit a written revocation of the authorization. However, your
                        decision to revoke the authorization will not affect or undo any use or
                        disclosure of information that occurred before you notified us of your
                        decision to revoke your authorization.
                    </p>
                    <h3>Your Rights</h3>
                    <p>
                        You have certain rights under the federal privacy standards. These include:
                    </p>
                    <ul>
                        <li>
                            The right to request restrictions on the use and disclosure of your
                            protected health information for treatment, payment, or health care
                            operations. You have the right to restrict our disclosure to only
                            certain individuals involved in your care or the payment for your care,
                            such as family members and friends. We are not required to agree to your
                            request; however, if we do not agree, we are bound by our agreement
                            except when otherwise required by law, in emergencies, or when the
                            information is necessary to treat you. You must make your request in
                            writing to the attention of the Privacy Officer.
                            <br />
                            Your request must be described clearly and concisely: <br /> a) The
                            information you wish restricted
                            <br /> b) Whether you are requesting to limit our practice’s use,
                            disclosure, or both <br /> c) To whom you want the limits to apply
                        </li>
                        <li>
                            The right to receive confidential communications concerning your medical
                            condition and treatment.
                        </li>
                        <li>
                            The right to inspect and copy your protected health information. We will
                            provide a copy or summary of your health information, usually within 30
                            days of your request. We may charge a reasonable, cost-based fee. Our
                            practice may deny your request to inspect and/or copy in certain limited
                            circumstances; however, you may request a review of the denial.
                        </li>
                        <li>
                            The right to amend or submit corrections to your protected health
                            information. This request must be made in writing and submitted to
                            Privacy Officer with reasons to support your request. We may deny your
                            request if you ask us to amend information that is in our opinion:{' '}
                            <br />o Accurate and complete <br />o Not part of the health information
                            kept by or for the practice <br />o Not part of the health information
                            which you are permitted to inspect and copy <br />o Not created by our
                            practice, unless the individual or entity that created is not available
                            to amend the information <br />o We will provide a written explanation
                            for any denial in 60 days.
                        </li>
                        <li>
                            The right to receive an accounting of how and to whom your protected
                            health information has been disclosed. We will include all the
                            disclosures except for those about treatment, payment, and health care
                            operations, and certain other disclosures (such as any that you asked us
                            to make). We will provide one accounting a year for free but will charge
                            a reasonable, cost-based fee if you ask for another one within 12
                            months.
                        </li>
                        <li>
                            The right to receive a printed copy of this notice, even if you have
                            agreed to receive the notice electronically.
                        </li>
                    </ul>
                    <h3>Requests to Inspect Protected Health Information</h3>
                    <p>
                        You may generally inspect or copy the protected health information that we
                        maintain. As permitted by federal regulation, we require that requests to
                        inspect or copy protected health information be submitted in writing. You
                        may obtain a form to request access to your records by contacting your
                        physician and/or privacy officer. Your request will be reviewed and will
                        generally be approved unless there are legal or medical reasons to deny the
                        request.
                    </p>
                    <h3>Bloom La Vie Health’s Duties</h3>
                    <p>
                        We are required by law to maintain the privacy of your protected health
                        information and to provide you with this notice of privacy practices. We
                        also are required to abide by the privacy policies and practices that are
                        outlined in this notice.
                    </p>
                    <h3>Right to Revise Privacy Practices</h3>
                    <p>
                        As permitted by law, we reserve the right to amend or modify our privacy
                        policies and practices. These changes in our policies and practices may be
                        required by changes in federal and state laws and regulations. Upon request,
                        we will provide you with the most recently revised notice on any office
                        visit. The revised policies and practices will be applied to all protected
                        health information we maintain.
                    </p>

                    <h3>Complaints</h3>
                    <p>
                        If you would like to submit a comment or complaint about our privacy
                        practices, you can do so by sending a letter outlining your concerns to:
                    </p>
                    <br />
                    <strong>Bloom La Vie Health</strong>
                    <br />
                    <strong>9787 N Cedar Ave</strong>
                    <br />
                    <strong>Kansas City, MO 64157</strong>
                    <p>
                        If you believe that your privacy rights have been violated, you should call
                        the matter to our attention by sending a letter describing the cause of your
                        concern to the same address. You will not be penalized or otherwise
                        retaliated against for filing a complaint. Effective Date This notice is
                        effective on or after 8/1/2024.
                    </p>
                </Article>
            </DefaultLayout>
        </main>
    );
};

export default Policy;

export const query = graphql`
    query PrivacyPolciyHero {
        allFile(filter: { relativePath: { eq: "privacy-policy-hero.jpg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
        }
    }
`;
